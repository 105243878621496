.about_header_bg {
  background-image: url("../Images/homepage_bg.png");
  background-size: 100% 100%;
  height: 100%;
  /* padding-top: 14%; */
  background-position: 100%;
  background-repeat: no-repeat;
  padding-bottom: 6%;
}

.about_header_bg:before {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("../Images/bg_circle.png");
  background-repeat: no-repeat;
  position: absolute;
}

.pageBlur {
  opacity: 0;
}
