/* .homePageHeading {
  text-align: center;
  margin: 0 auto;
  max-width: 480px;
} */
.font-18{
  font-size: 18px !important;
}
.FAQ .FAQrow {
  padding-top: 50px;
}

.FAQrow i {
  float: right;
  color: #368ef5;
}

.faq_section .FAQtitle {
  color: #368ef5;
  padding-bottom: 10px;
  text-transform: capitalize;
}
.FAQrow .panel {
  box-shadow: 0 0 10px #c1c1c124;
  border-radius: 10px;
  margin-bottom: 15px;
  background: white;
  max-width: 99%;
  margin-left: auto;
  border: 0;
}
.FAQrow {
  padding-top: 50px;
}
.faq_section .FAQtitle {
  color: #368ef5;
  padding-bottom: 10px;
}

.faq_section h4 > a {
  color: black;
  font-size: 16px;
}

.FAQrow .panel-heading {
  background: none;
}

.faq_section .FAQsecond {
  padding-top: 40px;
}

/* FAQ's Section  */
.faq_section {
  background-color: #f7f8f9;
  box-shadow: none;
  padding-top: 60px;
}

.faq_section .panel-default > .panel-heading + .panel-collapse > .panel-body {
  color: #7a7a7a;
  font-size: 13px;
  padding: 10px 70px 30px 30px;
}

.faq_section .panel-default > .panel-heading {
  padding: 0;
  color: #212121;
}
ul.sidebar-inner-Container li a {
  cursor: pointer;
}

.FAQrow .panel-heading .panel-title > a {
  background: none;
  font-size: 13px !important;
  font-weight: 400;
  padding: 22px 30px;
}
