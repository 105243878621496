* {
  font-family: "Poppins";
}
html,
body {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

/* free-div */
.free-div {
  width: 100%;
  background-color: #007bffab;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.free-div p {
  margin-bottom: 0;
  margin-right: 20px;
  color: #fff;
  font-weight: 600;
}

.free-div span {
  background-color: #fff;
  color: #007bffab;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
}
.free-user {
  color: lightgray !important;
  cursor: no-drop !important;
}
/* free-div end */

.font-36 {
  font-size: 36px;
  font-family: "Poppins";
}
div#basic-menu_video_settings
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  width: 71px;
  margin-top: 26px;
}
.video_inner > div {
  width: 100% !important;
  height: 100% !important;
}
.video_inner.layer_pic:before {
  content: "";
  position: absolute;
  height: 99%;
  width: 100%;

  background-image: url("../Images/overlay_video.png");
  background-repeat: no-repeat;
  background-size: cover;

  /* PMO */
  opacity: 0;
}
.video_inner.layer_pic {
  position: relative;
}

div#basic-menu_video_settings
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  ul
  div {
  text-align: center;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
input.forum_input {
  margin-bottom: 20px;
  border-radius: 11px;
  height: 100px;
  border: 1px solid #007bffab;
}
.d-flex.forum_form {
  flex-direction: column;
}
div#basic-menu_video_settings
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  ul
  div[orientation="vertical"]
  div:hover {
  background-color: #fafafb;
}

div#basic-menu_video_settings
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  ul
  div[orientation="vertical"]
  div {
  cursor: pointer;
}
.pageBlur {
  opacity: 0;
}
.modal_show.pad-0.col-md-6 {
  z-index: 9999;
}
.show_overlay:before {
}
.modal-Contaier {
  background: #000000bf;
}
.payment-cards-modal {
}
.modal-content {
  border-radius: 20px;
}

@media (min-width: 576px) {
  .payment-cards-modal .modal-dialog {
    max-width: 1000px;
  }
}

.debit-add-card-div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

.controls_main svg {
  color: gray;
}
.cus_width_third.blue {
  width: 84%;
}
.font-40 {
  font-size: 40px;
}
.sidebar_items.sidebar_logo {
  height: 70px !important;
  margin-bottom: 31px;
}
.l_border {
  border-left: 1px solid #b9b9b8;
}

.nav_cont.container {
  max-width: 65%;
}

.nav_main.navbar {
  padding-top: 5%;
  padding-bottom: 9%;
}
.j-end {
  justify-content: flex-end;
}

.third-col-right {
  max-width: 74%;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
}
.blue {
  color: #3792ec !important;
}
.klanra_div {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
}

.btn_div_main {
  width: 50%;
  justify-content: space-between;
  display: flex;
  margin: auto;
  color: #fff;
}
.btn_div_main button {
  color: #fff !important;
}

.third_sec_bg {
  background-image: url("../Images/third_bg.png");
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sp_b_mid {
  display: flex;
  justify-content: center;
}
.speacial_input {
  background-color: #fafafb;
  padding-left: 10px;
  border: 1px solid #f1f1f5;
  width: 90%;
  height: 50px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.mb-5.sidebar_logo {
  margin-bottom: 90px !important;
}
.mb-5.sidebar_logo {
  margin-bottom: 90px !important;
}
.controls_main {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}
.bnf_main_show .bnf_heading h3.mb-4 {
  font-weight: 600;
}
.download_pdf_inner button {
  color: #fff !important;
}
.category_videos_right.d-flex.heading_space.mb-4 > p:nth-child(1) {
  /* border-bottom: 2px solid; */
  padding-bottom: 10px;
}
.seekbar_width {
  width: 80%;
}
.video_fullscript.onn .seekbar_width .d-flex.a-end.j-center span {
  margin-top: 0 !important;
}
.dashboardSection {
  padding-left: 5%;
}
.dashboardHeadingContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dashboardContantContiner1 {
  background: #349fff;
  padding: 20px 15px;
  border-radius: 15px;
  color: white;
}

.dashboardContantContiner1 p {
  color: white;
}

.dashboardContantContiner2 {
  background: #e1f1ff;
  padding: 20px 15px;
  border-radius: 15px;
  color: #3fa3ff;
}

.dashboardContantContiner2 p {
  color: #3fa3ff;
}

.backBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.backBtnContainer p {
  margin: 0px;
}

.first.d-flex {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.second.d-flex {
  display: flex;
  max-width: 20%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.sidebar_items.sidebar_logo {
  height: 70px !important;
  margin-bottom: 31px;
}
.video_div > div:nth-child(1) {
  width: 100% !important;
  height: 100% !important;
}
.sidebar_items {
  font-weight: bold;
  color: #92929d;

  cursor: pointer;
}
.sidebar_items.activeSidebar {
  /* font-size: 18px; */
  color: #349fff;
}

.btn-success_b {
  background-color: #008000a1 !important;
}
.sidebar_items {
  font-size: 14px;
  width: 66px;
}

.btnPdf.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  background-color: #ffc542;
  border-color: #ffc542;
}
.btnPdf .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #ffc54280;
}
.cursorPointer {
  cursor: pointer;
}

.sidebar_items.activeSidebar {
  background-color: #349fff70;
  height: 100px !important;
  display: flex;
  justify-content: center;
  color: #349fff;
}
.activeImage {
  filter: invert(62%) sepia(40%) saturate(5181%) hue-rotate(185deg)
    brightness(99%) contrast(104%);
}
.selectedItem {
  color: #349fff;
}

.sidebar_items {
  height: 100px !important;
  display: flex;
  justify-content: center;
}
p {
  font-size: 18px;
}
.p-width {
  /* max-width: 80%; */
  white-space: pre-line;
}
.p-width1 {
  max-width: 93%;
  white-space: pre-line;
}
.mar_p_header p {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}
.header_btn_width button {
  border: 1px solid;
  border-radius: 20px;
  width: 130px;
}

.text-end {
  text-align: end;
}
.padding_left_second_section {
  /* padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end */
  /* margin-left: auto; */
  margin-left: 4%;
  max-width: 26%;
}
.third_section_right {
  /* zoom: 1.2;
  margin-left: 50px; */
  margin-left: 15%;
}
ul.sitemap_ul_li {
  padding: 0px 15px;
}

.accordion_style_b {
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: black;
  background: #c3eafb
    url(http://idefisc-corp.fr/wp-content/themes/konzept/images/white-highlight.png)
    top left repeat-x;
  border: 2px solid #b5d9ea;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgb(0 0 0 / 50%) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
  background-color: #c3eafb !important;
}
.video_fullscript.onn {
  position: unset;
}

.video_fullscript.onn > .video_div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow-y: hidden;
}

.video_fullscript.onn > .video_div > .controls_main {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  background: white;
}
.video_fullscript.onn .video_div > div:nth-child(1) {
  width: auto !important;
  height: auto !important;
}

.sitemap_head h3 {
  color: #3792ec;
}
.accordion_style_b.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded:before {
  display: none;
}
.third_section.container .row {
  display: flex;
  align-items: center;
}
.home_header_bg {
  background-image: url("../Images/homepage_bg.png");
  background-size: 100% 100%;
  height: 100%;
  /* padding-top: 14%; */
  background-position: 100%;
  background-repeat: no-repeat;
  padding-bottom: 6%;
}
.term_and_pp_header_bg {
  /* background-image: url("../Images/homepage_bg.png");
  background-size: 100% 100%; */
  /* height: 800px; */
  /* margin-bottom: 5%; */
  /* padding-top: 14%; */
  background-position: 100%;
  background-repeat: no-repeat;
}
.term_and_pp_heading {
  text-align: center;
  margin: 0 auto;
  font-size: 35px;
  font-weight: 600;
}
p.ml-2.debit-credit-card-label {
  margin: 0;
}
.twenty_percent {
  max-width: 18%;
  display: inline-block;
  vertical-align: text-top;
  margin: 0% 1%;
}

p.ml-2.debit-credit-card-label {
  margin: 0;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.green-btn {
  background-color: green;
  color: #fff !important;
}
.warning_div {
  height: 90px;
  background-color: #3792ec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  color: #fff;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}

.red-btn {
  background-color: red;
  color: #fff !important;
}

.blue-btn {
  background-color: #3792ec;
  color: #fff !important;
}
.text-center.first_section_main.col-md-12 {
  padding-top: 3%;
}
.mr-3 {
  margin-right: 1.5rem;
}
.home_header_bg h3.mb-5 {
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 600;
}
.home_header_bg h5 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 400;
}
.header_btn_width button {
  color: #3792ec !important;
}
.rec-carousel-wrapper:before {
  content: "";
  width: 18%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 92%) 0%,
    rgb(255 255 255 / 48%) 91%
  );
  z-index: 9;
  position: absolute;
}
.testi_top_mar.container-fluid {
  margin-top: 90px;
  margin-bottom: 180px;
}
.rec-carousel-wrapper:after {
  content: "";
  width: 18%;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 43%) 0%,
    rgba(255, 255, 255, 1) 91%
  );
  z-index: 9;
  position: absolute;
}
button.header_btn1.btn.btn-primary {
  background-color: #3792ec;
  border-color: #fff;
  color: #fff !important;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 35px;
  padding: 17px 39px;
}
.second_sec_top.container {
  margin-top: 25%;
}
button.header_btn2.btn.btn-primary {
  background-color: #fff;
  border-color: #fff;
  font-size: 24px;

  color: #3792ec !important;
  font-weight: 600;
}
.banner_width.container-fluid {
  /* overflow: hidden; */
}
.whito {
  color: #fff !important;
}
/* .header_btn_width {
  width: 200px;
} */
.nav_header_div h3.font-24.blue {
  font-weight: 600;
}

.second_sec_top.container-fluid .font-36 {
  font-size: 36px;
  font-family: "Poppins";
  font-weight: 600;
  width: 92%;
}

.header_btn_width button {
  height: 45px;
  width: 145px;
  background-color: #fff;
  border-color: #3792ec;
  font-weight: 600;
  color: #3792ec;
}
.nav_header_div h3 {
  margin-bottom: 0;
}
.nav_header_div {
  display: flex;
  justify-content: space-between;
  max-width: 62%;
  margin: auto;
  align-items: center;
  width: 100%;
}
.banner_width.container {
  max-width: 61%;
}

.d-flex.mar_p_header p {
  margin-right: 50px;
}
.header_left_new {
  max-width: 62%;
  margin-left: auto;
}
.navbar_main {
  position: absolute;
  padding-top: 4%;
}
.third_section.container {
  padding-top: 11%;
}
.Third_icon_main {
  display: flex;
}

.Third_icon_main div {
  width: 80px;
  margin-right: 20px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  box-shadow: 0px 3px 13px #00000045;

  border-radius: 10px;
}
.Third_icon_main div img {
  margin-top: 10px;
}
.third_left_btns.mt-5 {
  display: flex;
  align-items: center;
}
::marker {
  font-size: 13px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.package_desc_main {
  display: flex;
  align-items: flex-start;
}

.tick_size svg {
  font-size: 32px;
  color: green;
  margin-bottom: 16px;
}

.package_desc {
  margin-left: 20px;
}
.package_desc p {
  margin: 0;
}

.package_desc ul {
  padding-left: 1rem;
}
.third_left_btns.mt-5 > h6 {
  margin-right: 32px !important;
  color: #3792ec;
  border-right: 1px solid;
  padding-right: 32px;
  /* margin: 0 !important; */
  margin-bottom: 0 !important;
  height: 52px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third_left_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 80px;
  background-color: #3792ec;
}
.newPadding {
  padding: 30px;
}
.text-center.newGray {
  background-color: #f9fbfc;
  padding-top: 45px;
}
.last_sec_bg {
  background-image: url("../Images/pricing_bg.png");
}
p.newTag.font-16 {
  width: fit-content;
  margin: auto;
  transform: translate(0px, 22px);
  background-color: #ffffff;
  padding: 9px;
  border-radius: 40px;
  color: #58a4ef;
}
.package_main {
  width: 70%;
  /* border: 2px solid #3792ec; */
  border-radius: 12px;
  background-color: #fff;
  /* padding: 30px; */
  margin-bottom: -16px;
  box-shadow: 0px 20px 40px #eee;
}
.third_section.container {
  max-width: 75%;
}

.signup_btn {
  /* background-color: #3792ec;
  border-color: #3792ec; */
  color: #fff !important;
  border-width: 2px;
  font-weight: 600;
  border-radius: 8px;
  font-size: 24px;
}

.red {
  color: red !important;
}
.padding_left_second_section.col-md-6 p {
  color: #616368;
}

.third_section.container p.p-width1.pb-4 {
  color: #7c7a85;
}
.p_colors.col p {
  color: #7c7a85;
}
.p_colors.col h4 {
  font-size: 36px;
  font-weight: 600;
}
.package_main h3 {
  color: #3792ec;
  margin-bottom: 24px;
}

.package_main h3 {
  font-size: 30px;
}
button.third_left_btn.btn.btn-primary {
  background-color: #3792ec;
  border-color: #3792ec;
  color: #fff !important;
}

.signin-btn.col-md-12 button {
  color: #fff !important;
}
.footer_bg.container-fluid .row {
  margin-top: 5%;
  color: #fff;
}
a.header_btn1.mr-3.btn.btn-primary {
  background-color: #3792ec;
  border-color: #3792ec;
  color: #fff !important;
  border-width: 2px;
  font-weight: 600;
  border-radius: 8px;
  font-size: 24px;
}

.banner_width.container {
  max-width: 61%;
}
.pricing_table {
  position: relative;
}

img.price_tag {
  position: absolute;
  right: 7%;
  top: 11%;
}
.signup-btn.col-md-12 button {
  color: #fff !important;
}
.package_price_main {
  position: relative;
  width: 35%;
  background-color: #3792ec;
  color: #fff;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-top: -10px;
  list-style: none;
  color: #fff !important;
  text-decoration: none !important;
}

.banner_first {
  background-color: #fff;
  display: flex;
  /* width: fit-content; */
  border: 1px solid #3792ec;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 14px;
}
.logo_main {
  font-weight: 600;
}
.banner_first p {
  margin-bottom: 0;
}

.banner_first svg {
  background-color: #3792ec;
  display: flex;
  /* width: fit-content; */
  border: 1px solid #3792ec;
  align-items: center;
  color: #fff;
  border-radius: 12px;
}
.three_vw {
  font-size: 3vw;
}
.one_vw {
  font-size: 1vw;
  margin-left: 5px;
}
.a_noStyle {
  display: flex !important;
  justify-content: center !important;
  color: #242f51 !important;
}
.testi_desc {
  color: #616368;
  font-weight: 300;
  width: 84%;
  margin: auto;
}

.testi_Name {
  color: #616368;
  font-weight: 500;
}
.newPadding {
  padding: 30px;
}
.package_price_main p {
  /* margin-top: 19px; */
  margin-bottom: 0;
}
.footer_bg {
  background-image: url("../Images/footer_bg.png");
  background-size: cover;
  height: 179px;
  background-repeat: no-repeat;
}
.footer_bg.container-fluid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer_bg .row {
  max-width: 77%;
  width: 100%;
}

.noPreviewContainer {
  width: 65%;
}

.noPreviewImg {
  object-fit: contain;
  width: 100%;
}

.MobileRestrictedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.controls_main.opacity_0 {
  opacity: 0;
}
.controls_main.opacity_0.showing {
  opacity: 1;
}
.mobileRestrictedText {
  font-size: 20px;
  font-weight: 600;
  color: #3090ef;
  max-width: 65%;
  text-align: center;
}

.navbar_section_PP {
  padding-top: 5%;
  padding-bottom: 2%;
}
.term_and_pp_Container {
  background-color: #3090ef;
  /* background-image: linear-gradient(
    180deg,
    #3090ef,
    #7bb4ee,
    #9ac7f4
  ); */
  background-attachment: fixed;
  min-height: 100vh;
}

.content {
  background: white;
  border-radius: 10px;
  padding: 15px 20px /* color: black !important; */;
}
.term_and_pp_Container nav ul {
  list-style-type: none;
  margin: 0;
}
.term_and_pp_Container nav ul li {
  /* padding-bottom: 15px; */
  background: #fff;
  padding: 20px 10px;
  text-align: center;
  margin-bottom: 15px;
  border-radius: 10px;
}
.term_and_pp_Container nav .sidebar-inner-Container li a {
  text-decoration: none;
  color: #000;
  /* color: #c9c9c9; */
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
}
.term_and_pp_Container nav .sidebar-inner-Container li a.active:after {
  content: "";
  border-bottom: 0px;
  position: fixed;
  width: auto;
  height: auto;
  margin-left: 0px;
}
.term_and_pp_Container nav .sidebar-inner-Container li a:hover {
  color: #349fff;
}

.content_main {
  border: 1px solid #eeecf2;
}
.font-14 {
  font-size: 14px;
}
.cont_text {
  color: #696974;
  font-weight: 500;
}
.videos_desc_main {
  border: 1px solid #eeecf2;
}

.border1 {
  border: 1px solid #eeecf2;
}

.a-center {
  align-items: center;
}
.inner-para-col {
  color: #000 !important;
}
.inner-para-col span {
  color: #000 !important;
}
.inner-para-col p span {
  color: #000 !important;
}
.sub-footer {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 5%;
}
.sub-footer a {
  color: white;
  text-decoration: none;
}
.sidebar_items img {
  width: 40px !important;
  height: 40px !important;
}
.full_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sections_name.col-md-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cus_width_sitemap.row {
  max-width: 78%;
  margin: auto;
}

.home_header_bg:before {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("../Images/bg_circle.png");
  background-repeat: no-repeat;
  position: absolute;
}

.sections_name.col-md-6 div:before {
  content: "click Me!";
  position: absolute;
  width: 60px;
  height: 60px;
  padding: 10px;
  top: -20px;
  right: 80px;
  background-color: #3792ec;
  display: none;
  border-radius: 50%;
  font-size: 13px;
  justify-content: center;
  text-align: center;
  color: #fff;
}
.header_btn_width button:hover {
  background-color: #349fff;
  color: #ffffff !important;
  border-color: #349fff;
}
.sections_name:hover :before {
  display: flex !important;
}

.sections_name.col-md-6 div {
  background-color: #f5f7fa;
  /* border-color: #db15d3; */
  border: 2px solid #009ffa;
  width: 225px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 23px;
}

.bnf_main_show {
  z-index: 99999;
  margin-top: 5%;
}
.third_section.container .Third_icon_main img {
  width: 30px;
}

.mini_p {
  font-size: 14px !important;
}
.mini_p p {
  font-size: 14px !important;
}
.bb-1 {
  border-bottom: 1px solid gray;
}

.download_pdf_btn {
  background-color: #ffc542;
  border-color: #ffc542;
  width: 100px;
  border-radius: 10px;
}
.sidebar_pages {
  padding-left: 29px;
  padding-right: 150px;
}

.chapters_show {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.chapters_show.videos_btn h6 {
  width: 170px;
  background-color: #f5f7fa;
  border: 1px solid #009ffa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  padding: 15px;
  text-align: center;
  overflow-wrap: anywhere;
}
.chapters_show h6 {
  width: 150px;
  background-color: #f5f7fa;
  border: 1px solid #009ffa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  padding: 10px;
  text-align: center;
}
.bnf_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
}
.bnf_heading h3 {
  padding-right: 70px;
}
p.mini_p.active_bottom:after {
  content: "";
  border-bottom: 2px solid #000;
  position: absolute;
  width: 100%;
  height: 16px;
  bottom: 0;
  left: 0;
}
.chap_vid_row.row {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
p.mini_p.active_bottom {
  position: relative;
  margin-bottom: -1px;
}

.sidebar_search {
  position: relative;
  display: flex;
  justify-content: center;
}

.sidebar_search svg {
  position: absolute;
  right: 10% !important;
  top: 25% !important;
  color: #92929d;
}
.videos_desc_main {
  background-color: #fff;
  padding: 23px;
}
main.MuiBox-root {
  background-color: #f8f8f8;
}
.search_main {
  width: 300px;
}
.menu_main {
  /* width: 300px; */
}
.search_main.hide {
  visibility: hidden;
}
a.active {
  color: #349fff;
  text-decoration: none !important;
}

/* a.active:after {
  content: "";
  border-bottom: 2px solid #349fff;
  position: absolute;
  width: 95px;
  height: 16px;
  left: 12%;
  margin-left: -8px;
} */

.d-flex.center_div_header {
  position: relative;
}
.sidebar_search input {
  width: 90%;
  border-radius: 17px;
  background-color: #fafafb;
  border-color: #fafafb;
  font-size: 14px;
  height: 50px;
  padding-left: 10px;
  border: 0;
}
.sidebar_search input:focus-visible {
  outline-width: 0px;
  outline: none !important;
}
.searchInputBorder {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.f-direction-c {
  flex-direction: column;
}
.sidebar_pad_top {
  padding-top: 100%;
}

.sidebar_pad_top > ul > div {
  /* padding-top: 20%; */
  margin-bottom: 40px;
}
.sidebar_logo img {
  margin-top: 15px;
  width: 43px;
}
.download_pdf_inner {
  background-color: #fafafb;
  border-radius: 17px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px;
  flex-direction: column;
}
.support_textarea {
  position: relative;
}
.faq_heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.sidebar_search {
  width: 330px;
}

.right_chevron {
  position: absolute;
  bottom: 2%;
  right: 0%;
  background-color: #349fff;
  border-radius: 15px;
  padding: 5px 13px;
  color: #fff;
}
.support_textarea textarea {
  width: 100% !important;
  overflow: hidden;
  min-height: 270px;
  height: 270px;
  border-radius: 15px;
  padding: 10px;
  margin-top: 39px;
}
.d-flex.bb-1 {
  margin-top: 50px;
}
.d-flex.bb-1 > p {
  margin-right: 50px;
}
.font-60 {
  font-size: 60px !important;
}
p.mini_p.active_bottom {
  border-bottom: 1px solid;
  font-weight: 600;
}
.active_bottom {
  border-bottom: 3px solid;
  font-weight: 600;
}
.download_pdf_inner div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 20px; */
  align-items: baseline;
}
/* .d-flex.side_contents > p {
  margin-right: 30px;
} */
.heading_space > p {
  margin-right: 30px;
}
.d-flex.center_div_header {
  align-items: center;
  justify-content: space-evenly;
  width: 15%;
}
.d-flex.center_div_header p {
  margin-bottom: 5px;
  margin-right: 40px;
}
.signed_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e2e2ea;
  background-color: #fff;
  padding: 7px 0px;
}
.video_search_data_container {
  position: absolute;
  z-index: 1;
  /* margin-left: 24px; */
  width: 330px;
  display: flex;
  justify-content: center;
}
.video_search_data {
  background: #fafafb;
  width: 90%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.video_search_data_container .video_search_data p {
  padding: 10px 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h6.center_text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.font-16 {
  font-size: 16px;
}
.font-40 {
  font-size: 40px;
}
.blue_numbering {
  width: 42px;
  height: 42px;
  background-color: #349fff;
  display: flex;
  color: #fff !important;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
}
.d-flex.side_contents p {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .d-flex.side_contents >p {
  margin-right: 30px;
} */

.content_main {
  background-color: #fff;
  padding: 25px;
}
.MuiBox-root.css-k008qs header.MuiPaper-root {
  background-color: #fff;
  color: #000;
}
.MuiBox-root.css-k008qs header.MuiPaper-root > div {
  padding: 0;
}
.questions_pic_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 21px;
  border: 1px solid #d2d2de;
  margin-right: 17px;
  border-radius: 20px;
}

.questions_main {
  padding: 20px;
  display: flex;
  background-color: #fff;
  border-radius: 23px;
  margin: 0px;
}
.cus_sidebar_wrapper {
  display: flex;
}
.vbold {
  font-weight: 600 !important;
}
p.bold {
  font-weight: 600;
}
.a-end {
  align-items: flex-end;
}
.j-center {
  justify-content: center;
}
.p_very_small {
  font-size: 12px;
}
.blue_ask {
  background-color: #349fff;
  color: #fff;
  padding: 20px;
  border-radius: 15px;
}

.questions_div {
  width: 95%;
}
.questions_pic_div {
  width: 33%;
}

.questions_pic_div img {
  width: 50%;
  margin: 0 auto;
}
.font-24 {
  font-size: 24px;
}

.cus_sidebar_main div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar_screen_main {
  width: 100%;
  height: 100vh;
  background-color: #fafafb;
}
.bg_white {
  background-color: #fff !important;
  min-height: 93vh;
}

.no-content-container {
  background-color: #fff;
  min-height: 90vh;
  width: 65%;
  margin: 25px 50px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
}
.no-content-container .no-content-image {
  width: 170px;
  height: 170px;
  object-fit: contain;
}
.no-content-container p {
  font-size: 25px;
  font-weight: 700;
  color: lightgray;
  margin-top: 10px;
}

.cus_sidebar_main {
  border-right: 1px solid #e2e2ea;
}
.bg_color_videos {
  margin-bottom: 50px;
  padding-right: 4%;
}
.mb-5.activeSidebar {
  color: #349fff;
  font-weight: 600;
}
p.mini_p.fade_font {
  color: #92929d;
}
p.font-weight {
  font-weight: 500;
}

a {
  color: #000;
  text-decoration: none;
}
/* Pre reg Sign in */
.pre-reg {
  background-color: #3792ec;
  height: 100vh;
  /* background-image: linear-gradient(180deg, #3090ef, #7bb4ee, #9ac7f4);
  background-attachment: fixed;
  height: 100vh; */
}
.pre-reg .logo {
  margin-top: 30px;
}

.pre-reg .web-name {
  color: #fff;
  margin: 15px 0;
  font-weight: bold;
}
/* .row.style-h.row {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 35%;
  left: 50%;
  text-align: center;
} */
.pre-reg .signin-container {
  height: 365px;
  max-width: 45%;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 3% 2%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}

.pre-reg .signin-container h2 {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
}

.pre-reg .signin-container .input {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  height: 90px;
  justify-content: space-around;
}

.pre-reg .signin-container .input input {
  border-radius: 15px;
}

.pre-reg .signin-container .signin-btn {
  margin-top: 40px;
  height: 50px;
}

.pre-reg .signin-container .signin-btn button {
  background-color: #28bad8;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #3090ef;
  font-weight: 600;
}
.pre-reg .privacy-policy {
  margin-top: 15px;
}

.pre-reg .privacy-policy p {
  color: #fff;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
}

.serviceContainer .imageContainer {
  /* width: 100px;
  height: 100px;
  margin-right: 25px;
  border-radius: 50px; */
  width: 186px;
  height: 90px;
  margin-right: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.serviceContainer .imageContainer img {
  width: 75%;
  max-width: 100%;
  /* height: 70px; */
  object-fit: contain;
}

/* pre reg sign up */
.pre-reg {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pre-reg {
  background-color: #3090ef;
  height: 100vh;
}
.pre-reg .logo {
  margin-top: 30px;
}

.pre-reg .web-name {
  color: #fff;
  margin: 15px 0;
  font-weight: bold;
}
.bnf_main_show .sidebar_search.mb-4 {
  width: unset !important;
}
img.user_img {
  width: 37px;
}

.menu_main svg,
.menu_main button,
.menu_main img {
  color: gray !important;
  cursor: pointer;
}
.menu_main svg,
button {
  color: gray !important;
}
.menu_main button:after {
  /* content: ""; */
  position: absolute;
  width: 16px;
  height: 16px;
  /* color: red; */
  /* left: 100%; */
  /* background-color: red; */
  /* background-image: url("../Images/play.png"); */
  /* background-repeat: no-repeat;
  transform: rotate(107deg); */
}
.input_eye {
  position: relative;
}

.input_eye svg {
  position: absolute;
  right: 5%;
  top: 17%;
  color: #b0b0b8;
}
.pad-singup {
  padding: 3% 4%;
}
.pad-singup h2 {
  margin-top: 22px;
}
.pass-strength.col-md-12 > div div {
  padding: 3px;
}
.signin-container form {
  width: 100%;
}
.pre-reg .signup-container {
  /* height: 588px; */
  max-width: 45%;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 0;
  /* padding: 3% 2%; */
  padding-bottom: 0;
}

.pad-singup p.para-text {
  color: #92929d;
}

.signup_last_text {
  font-size: 16px;
  color: #3792ec;
  width: 100%;
  background-color: #fafafb;
  margin: 0;
  padding: 20px 0px !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: 600;
}
p.para-text {
  font-size: 16px;
}
.pre-reg .signup-container h2 {
  color: #000000;
  font-size: 22px;
  font-weight: bold;
}

.pre-reg .signup-container .input {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  /* height: 150px; */
  justify-content: space-around;
}

.pre-reg .signup-container .input input {
  border-radius: 10px;
}

.pre-reg .signup-container .pass-strength {
  width: 75%;
  margin: 23px auto;
}

.pre-reg .signup-container .para {
  margin: 25px 0;
}

.pre-reg .signup-container .signup-btn {
  margin-top: 40px;
  height: 50px;
}

.pre-reg .signup-container .signup-btn button {
  background-color: #28bad8;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #3090ef;
}

.pre-reg .signup-container .already-account {
  background-color: aliceblue;
  margin-top: 112px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 70px;
}
/* .row.style-h.row {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
} */

.input.col-md-12 input {
  height: 50px;
}

.input.input_styling.col-md-12 input {
  background-color: #fafafb;
  padding-left: 10px;
  border: 1px solid #f1f1f5;
  width: 100%;
}

.video_div.onn div {
  position: absolute;
  bottom: 0;
  right: 0;
}
.last_section.container .col-lg-4.col-md-12.col-sm-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/***** Colors & Sizes *****/
:root {
  --color-primary: #0088ce;
  --color-primary-tint: #fff;
  --color-accent: #005399;
  --color-accent-tint: #fff;
  --color-line: #ccc;

  --item-width: 200px;
  --item-gap: 20px;
}
/***** Vertical Sitemap *****/
.vsitemap {
  text-align: left;
}
.vsitemap * {
  box-sizing: border-box;
}
.vsitemap ul {
  margin: 0 var(--item-gap);
  list-style: none;
  padding: 0;
}
.vsitemap ul > li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 calc(var(--item-gap) / 2);
  position: relative;
}
.vsitemap ul > li:before {
  content: "";
  position: absolute;
  top: 1em;
  height: 1px;
  left: calc(-1 * var(--item-gap) / 2);
  width: calc(var(--item-gap) / 2);
  background: var(--color-line);
}

/* Draw Line */
.vsitemap ul > li:first-child:before {
  width: var(--item-gap);
  left: calc(-1 * var(--item-gap));
}
.vsitemap ul li:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--item-gap));
  left: calc((var(--item-gap) / 2) - var(--item-gap));
  bottom: 0;
  width: 1px;
  background: var(--color-line);
}
.vsitemap ul li:first-child:after {
  top: 1em;
}
.vsitemap ul li:last-child:after {
  bottom: auto;
  height: calc(var(--item-gap) + 1em);
}
.vsitemap ul li:only-child:after {
  display: none;
}

/* Box Item */
.vsitemap small {
  line-height: 1.5em;
  position: relative;
  font-size: 0.8em;
}
.vsitemap a {
  line-height: 1.5em;
  display: block;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 3px;
  width: var(--item-width);
  transition: background-color 0.4s;
}
.vsitemap a,
.vsitemap a:visited {
  background: var(--color-primary);
  color: var(--color-primary-tint);
}
.vsitemap a:hover,
.vsitemap a:active {
  background: var(--color-accent);
  color: var(--color-accent-tint);
}

/* Responsive */
/* tablet */
@media only screen and (max-width: 768px) {
  .pre-reg .signin-container {
    max-width: 75% !important;
  }

  .home_video_main video {
    margin-left: 0% !important;
  }
  .about-card {
    margin-bottom: 40px;
  }
  .vsitemap > ul > li > ul > li ul li {
    flex-direction: column;
  }
  .vsitemap > ul > li > ul > li ul li ul {
    margin-top: calc(var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li > ul li:after {
    left: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li > ul > li li:first-child:before {
    width: calc(var(--item-gap) / 2);
    left: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li > ul > li li:first-child:after {
    top: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li > ul > li li:only-child:after {
    display: block;
    height: calc(var(--item-gap) / 2 + 1em);
  }
}
/* mobile */

@media (max-height: 640px) {
  .pre-reg {
    height: auto !important;
  }
}

@media only screen and (max-width: 576px) {
  .vsitemap > ul > li ul li {
    flex-direction: column;
  }
  .vsitemap > ul > li ul li ul {
    margin-top: calc(var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul li:after {
    left: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li li:first-child:before {
    width: calc(var(--item-gap) / 2);
    left: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li li:first-child:after {
    top: calc(-1 * var(--item-gap) / 2);
  }
  .vsitemap > ul > li > ul > li li:only-child:after {
    display: block;
    height: calc(var(--item-gap) / 2 + 1em);
  }
}

/***** End Vertical Sitemap *****/

/***** Horizontal Sitemap *****/
.hsitemap * {
  box-sizing: border-box;
}
.hsitemap ul {
  list-style: none;
  padding: 0;
  margin: var(--item-gap);
  text-align: center;
}
.hsitemap li {
  position: relative;
}
.hsitemap ul ul ul {
  margin: 0 0 0 var(--item-gap);
  text-align: left;
}
.hsitemap > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hsitemap > ul > li > ul {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.hsitemap > ul > li > ul > li {
  margin: 0 calc(var(--item-gap) / 2);
}
.hsitemap > ul > li > ul > li li {
  margin: calc(var(--item-gap) / 2) 0;
}

/* Box Item */
.hsitemap small {
  line-height: 1.5em;
  position: relative;
  font-size: 0.8em;
}
.hsitemap a {
  line-height: 1.5em;
  display: block;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 3px;
  width: var(--item-width);
  transition: background-color 0.4s;
  position: relative;
  z-index: 2;
}
.hsitemap a,
.hsitemap a:visited {
  background: var(--color-primary);
  color: var(--color-primary-tint);
}
.hsitemap a:hover,
.hsitemap a:active {
  background: var(--color-accent);
  color: var(--color-accent-tint);
}

/* Draw line */
/* Horizontal line for first two level */
.hsitemap > ul > li > a:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  bottom: auto;
  height: calc(var(--item-gap) / 2);
  width: 1px;
  background: var(--color-line);
  z-index: 1;
}
.hsitemap > ul > li > ul > li > a:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--item-gap) / 2);
  left: 50%;
  bottom: auto;
  height: calc(var(--item-gap) / 2);
  width: 1px;
  background: var(--color-line);
  z-index: 1;
}
.hsitemap > ul > li > ul > li:before {
  content: "";
  position: absolute;
  top: calc(-1 * var(--item-gap) / 2);
  left: -50%;
  bottom: auto;
  right: 0;
  height: 1px;
  background: var(--color-line);
  z-index: 1;
}
.hsitemap > ul > li > ul > li:first-child:before {
  left: calc(50% - var(--item-gap) / 2);
}
.hsitemap > ul > li > ul > li:last-child:before {
  right: calc(100% - var(--item-width) / 2);
}

/* Vertical line for level 3 down */
.hsitemap > ul > li li li:before {
  content: "";
  position: absolute;
  top: 1em;
  height: 1px;
  left: calc(-1 * var(--item-gap) / 2);
  width: calc(var(--item-gap) / 2);
  background: var(--color-line);
}
.hsitemap > ul > li li li:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--item-gap));
  left: calc(-1 * var(--item-gap) / 2);
  bottom: 0;
  width: 1px;
  background: var(--color-line);
}
.hsitemap > ul > li li li:first-child:after {
  top: calc(-1 * var(--item-gap) / 2);
}
.hsitemap > ul > li li li:last-child:after {
  bottom: auto;
  height: calc(var(--item-gap) + 1em);
}
.hsitemap > ul > li li li:only-child:after {
  bottom: auto;
  height: calc(var(--item-gap) / 2 + 1em);
}

.hsitemap .blueBox > a {
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: black;
  background: #c3eafb
    url(http://idefisc-corp.fr/wp-content/themes/konzept/images/white-highlight.png)
    top left repeat-x;
  border: 2px solid #b5d9ea;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgb(0 0 0 / 50%) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
}

.greenBox li a {
  background-color: #b8da83 !important;
  border-color: #b8da83 !important;
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: black !important;
  background: #c3eafb
    url(http://idefisc-corp.fr/wp-content/themes/konzept/images/white-highlight.png)
    top left repeat-x;
  border: 2px solid #b5d9ea;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgb(0 0 0 / 50%) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px;
}
/***** End Horizontal Sitemap *****/

.answerContainer {
  border: 3px solid #33a0ff80;
  padding: 10px;
}

.forumTitle {
  text-transform: capitalize;
  font-weight: 500;
  color: #8b8a8a;
}

.forumText {
  color: #9f9f9f;
  font-weight: normal;
}
.mail_p a {
  color: #fff;
}
.ShowMedia {
  display: none;
}
.btnPdf.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  background-color: #349fff;
  border-color: #349fff;
}
.btnPdf .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #33a0ff80;
}
textarea.forum_input {
  margin-bottom: 12px;
  border-radius: 11px;
  height: 190px;
  border: 1px solid #007bffab;
  padding-top: 10px;
}

.forumSumitBtn {
  color: white !important;
}
.green {
  background-color: #008000a1 !important;
}
.home_video_main {
  width: 100%;
  margin-left: 11%;
}
.header_left_new {
  max-width: 62%;
  margin-left: auto;
}
.videoBig {
  z-index: 9;
  /* position: absolute;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  height: 830px;
}
.show_overlay:before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000085;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
}
.home_video_main video {
  border-radius: 20px;
  border: 4px solid #3792ec;
  margin-left: 2%;
  height: auto !important;
}

body.show_overlay {
  overflow: hidden;
}

.videoBig {
  z-index: 9;
  height: fit-content;
  position: fixed;
  width: 50%;
  top: 15%;
  right: 0;
  left: 0;
  margin: auto;
}
.show_overlay:before {
  width: 100%;
  z-index: 11;

  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.5215686274509804);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  position: fixed;
}
/* .modal_show.col-md-6 {
      position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
} */
.banner_width .row {
  display: flex;
  align-items: center;
}

.pad-0 {
  padding: 0 !important;
}
.videoBBB {
  z-index: 9;
  /* position: absolute;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  height: 100%;
}

.videoBBB div {
  height: 100% !important;
  margin: 0 auto !important;
  width: 100% !important;
}
.videoBig div {
  height: 100% !important;
  margin: 0 auto !important;
  width: 100% !important;
  /* box-shadow: 0px 20px 40px red; */
  /* width: fit-content; */
}
.videoBig div iframe {
  transform: scale(1.1);
  /* box-shadow: 0px 20px 40px red; */
}
.mar-t-cus {
  margin-top: 2rem;
}
.active .one-p:after {
  content: "";
  border-bottom: 2px solid #349fff;
  position: absolute;
  width: 95px;
  height: 16px;
  left: 12%;
  top: 100%;
  margin-left: -25px;
}

.whito {
  color: #fff !important;
}
.new_search .sidebar_search {
  width: 100%;
  display: block;
}

.new_search .sidebar_search input {
  width: 100%;
}

.active .two-p:after {
  content: "";
  border-bottom: 2px solid #349fff;
  position: absolute;
  width: 95px;
  height: 16px;
  top: 100%;
  margin-left: -80px;
}
.font-13 {
  font-size: 13px !important;
}
.loginMobileViewContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.loginMobileViewContainer p {
  font-weight: bold;
  text-align: center;
  width: 80%;
  margin: auto;
}

@media (max-width: 2560px) {
  .package_signed_header {
    width: 50%;
    margin: 0 auto;
  }
}
@media (max-width: 1600px) {
  .sidebar_screen_main {
    height: auto !important;
  }
  .padding_left_second_section {
    max-width: 50%;
    margin-left: 0%;
  }

  .package_main {
    width: 90% !important;
  }

  .active .one-p:after {
    left: 5% !important;
  }
}

@media (min-width: 1400px) {
  .bg_color_videos.mt-5.container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px;
  }
}

@media (min-width: 1400px) {
  .second_sec_top.container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .last_section.container {
    max-width: 84%;
  }

  .navbar-collapse.collapse.show .d-flex.mar_p_header {
    display: block !important;
  }

  .navbar-collapse.collapse.show .d-flex.mar_p_header p {
    margin-right: 0px !important;
    justify-content: center;
  }

  .navbar-collapse.collapse.show .header_btn_width {
    justify-content: center;
    display: flex;
  }
}
@media (max-width: 1024px) {
  .package_signed_header {
    width: 100%;
    margin: 0 auto;
  }
}
@media print {
  .cus_sidebar_wrapper {
    display: none;
  }
  .ShowMedia {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .pre-reg .signup-container {
    max-width: 80% !important;
  }
  .mar_p_header p {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .d-flex.mar_p_header {
    display: block !important;
    justify-content: center;
    align-items: center;
  }
  .d-flex.mar_p_header p {
    margin-right: 0px !important;
  }
  .header_btn_width {
    text-align: center;
  }
  .videoBig {
    width: 90% !important;
  }
  .one_price {
    margin-bottom: 50px;
  }
  .three_vw {
    font-size: 28px !important;
  }
  .home_video_main {
    margin-left: 0%;
  }
  /* .home_video_main video {
    border-radius: 20px;
    border: 4px solid #3792ec;
    margin-left: 0%;
    height: auto !important;
  } */
  .header_left_new {
    max-width: 100% !important;
    /* display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
  }
  p.one_vw {
    font-size: 13px;
  }
  .padding_left_second_section {
    max-width: 100% !important;
  }
  .cus_width_third.blue {
    width: 100% !important;
    text-align: center;
    margin-bottom: 50px;
  }
  .l_border {
    border-left: 0px solid #b9b9b8;
  }
  .footer_bg.container-fluid .mail_p {
    text-align: center !important;
  }

  .footer_bg.container-fluid .col-md-4 {
    text-align: center !important;
  }

  .padding_left_second_section {
    max-width: 50%;
    margin-left: 0%;
  }
  .testi_top_mar.container-fluid {
    margin-top: 50px !important;
    margin-bottom: 80px !important;
  }
}
@media (max-width: 600px) {
  div#root {
    /* display: none !important; */
  }
  .nav_cont.container {
    max-width: 100% !important;
  }
  .font-40 {
    font-size: 30px !important;
  }
  p {
    font-size: 14px !important;
  }
}
@media (max-width: 450px) {
  .package_main {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .explore_btn {
    font-size: 15px;
  }
}
@media (max-width: 360px) {
  .explore_btn {
    font-size: 13px;
  }
}

.thankyou--master {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("../Images/bg_circle.png");
  background-repeat: no-repeat;
}

.thankyou-container {
  justify-content: space-between;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin-top: 3rem;
}

.thankyou-inner {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.thankyou-inner h1,
.thankyou-inner p {
  text-align: center;
}
.package_desc p {
  font-weight: 500;
}

.row_flip.row {
  /* display: flex;
  flex-direction: row-reverse; */
  display: flex;
  justify-content: center;
}
.thankyou-wait {
  font-weight: bold;
  color: red;
  text-align: center;
  margin-top: 10px;
}
.payment-cards-modal .row > :nth-child(1) {
  padding-top: 10px;
}
.klanra_div > :nth-child(2) {
  width: 90% !important;
  text-align: right !important;
}
.btn_div_main {
  margin-bottom: 15px;
}

.applepay-text {
  text-align: center;
  margin: auto;
}

.applepay-container {
  padding: 3% 0 5% 0;
  width: 70%;
  margin: 0 auto;
}

.pm--buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Active-btn {
  background-color: #005399;
}

.pm--buttons button {
  width: 8rem;
  margin-bottom: 1rem;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  display: table-caption;
  padding-left: 10px;
  padding-right: 10px;
}

.menu-item-x {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.close-icon-container .MuiSvgIcon-root {
  cursor: pointer;
}

.btn-blue-x {
  background-color: #349fff;
  border: #349fff;
  color: #fff !important;
}

.c-p {
  cursor: pointer;
}

/* about */
.about-container h6 {
  text-align: center;
  margin-bottom: 50px;
}
.about-container {
  margin-top: 100px;

  /* height: 100vh; */
}
.about-container .about_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  /* height: 100vh; */
}

.about-card {
  box-shadow: 3rem 3rem 4rem #eee, -1rem -1rem 2rem #eee;
  /* width: 300px; */
  border-radius: 20px;
  padding: 20px;
  min-height: 400px;
  height: 100%;
  background-color: white;
}

.about-img-container {
  width: 100%;
  display: grid;
  place-items: center;
}

.about-img {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  object-fit: cover;
}

.about-title {
  margin: 20px 0;
  text-align: center;
  text-transform: capitalize;
}
/* 
.about-designation {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 5px 0px;
} */

.quote-icon {
  color: #3792ec;
  font-size: 1.5rem;
}

.about-description {
  margin-left: 10px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7);
}

.about-description p {
  display: contents;
}

.about-description a {
  color: #3792ec;
}

/* .about-learnMore {
  display: flex;
  justify-content: center;
  margin-top: 8px;
} */
/* 
.about-learnMore button {
  outline: none;
  border: none;
  background-color: #3792ec;
  padding: 10px;
  width: 150px;
  color: white !important;
  cursor: pointer;
  border-radius: 25px;
} */

/* .paymentModal-dialog {
  background-color: #0c2335;
}
.paymentModal-content {
  background-color: red;
  width: 1500px !important;

} */

.paymentModal-newStuffRow {
  padding: 15px;
}

.paymentModal-newStuffRow img {
  object-fit: contain;
  /* margin-left: 35px; */
  width: 85%;
  margin-top: 20px;
  margin-bottom: 40px;
}
.paymentModal-newStuffRow .paymentModal-newStuffRow-div1 {
  display: flex;
  align-items: flex-start;
}

.paymentModal-newStuffRow .paymentModal-newStuffRow-div1 p {
  text-align: left;
  margin-left: 10px;
  font-size: 14x;
  color: rgba(0, 0, 0, 0.7);
}

.paymentModal-newStuffRow .paymentModal-newStuffRow-div2 {
  display: flex;
  margin-left: 35px;
}

.paymentModal-newStuffRow .paymentModal-newStuffRow-div2 .cut {
  text-decoration: line-through;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.7);
}
.paymentModal-newStuffRow .paymentModal-newStuffRow-div2 .real {
  color: #3792ec;
  font-weight: 700;
}

.paymentModal-newStuffRow .add-plus-minus {
  text-align: left;
  margin-left: 35px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
  display: flex;
}

.paymentModal-newStuffRow .add-plus-minus span {
  cursor: pointer;
}
.paymentModal-newStuffRow .add-plus-minus span::selection {
  background: transparent;
}

.paymentModal-newStuffRow .add-plus-minus .two {
  margin: 0 20px;
  width: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.n-d {
  cursor: no-drop !important;
}

.paymentModal-new-head {
  color: #3792ec;
  font-weight: bold;
  text-align: left;
  margin-left: 35px;
}

.new-scroll-fix {
  height: 85vh;
  overflow-y: scroll;
}

.new-scroll-fix::-webkit-scrollbar {
  display: none;
}

.crditCardItem {
  border: 1px solid #3792ec;
  margin-bottom: 10px;
  border-radius: 15px;
}
.crditCardItem p {
  font-size: 12px;
  margin-bottom: 5px;
}

.ready__div {
  background: #349fff;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.ready__div h2 {
  font-weight: bold;
}

.ready__div .ready__p1 {
  margin: 30px 0;
  font-weight: 200;
  font-size: 20px;
}

.ready__div span {
  background-color: #fff;
  color: #349fff;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  margin-bottom: 30px;
  display: inline-block;
  cursor: pointer;
  transition: 0.5s all;
}

.ready__div span:hover {
  transform: translateY(-3px);
  box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.3);
  /* border: 2px solid white;
  background-color: transparent;
  color: #fff; */
}

.ready__div .ready__p2 {
  font-weight: 200;
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .free-div {
    display: none;
  }
}

.free-div-mbl span {
  background-color: #007bffab;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
}

.MobileRestrictedContainer .mobile-section-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 85vh;
}
