.MainContainer {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  margin: 20px auto;
  margin-bottom: 30px;
  padding: 30px;
  color: gray;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
  /* box-shadow: 0px 0px 50px 15px rgb(0 0 0 / 10%); */
}

.fieldContainer {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
  /* box-shadow: 0px 0px 50px 15px rgb(0 0 0 / 10%); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
.btnMainDiv {
  margin-top: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f0fafd;
  border: 1px solid #3792ec;
  color: #3792ec;
}
.btnMainDiv span {
  color: #3792ec;
  margin-left: 10px;
  font-weight: 600;
}

.nameInput {
  border: 0px;
}
.nameInput::placeholder {
  color: #aab7c4;
}
.nameInput:focus-visible {
  outline: none;
}
