.paymentCardModal {
}

.paymentModalDialog {
}

.paymentModalcontent {
  border-radius: 20px;
}
.div_between {
  display: flex;
  justify-content: space-between;
}
.modalHeader {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  background: #3792ec;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}
.modalBody {
  /* padding: 20px; */
}
.right {
  border-left: 1px solid #3792ec;
  padding: 20px;
}

.left {
  padding: 20px;
}

.closeIcon {
  position: absolute;
  top: -35px;
  right: -10px;
}
.headerTitle {
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.headerTitle span {
  font-size: 20px;
  margin-left: 5px;
  color: #000;
}
.bodyTitle {
  font-weight: bold;
  text-align: left;
  padding-bottom: 15px;
}
.leftTitle {
  color: #3792ec;
}

.addApplicationContainer img {
  object-fit: contain;
  width: 85%;
  margin-top: 20px;
  margin-bottom: 25px;
}
.checkBoxContainer {
  display: flex;
  align-items: flex-start;
  /* margin-bottom: 30px; */
}
.checkBoxContainer p {
  text-align: left;
  margin-left: 10px;
  font-size: 14x;
  color: rgba(0, 0, 0, 0.7);
}

.priceContainer {
  display: flex;
  margin-left: 35px;
}
.priceContainer :first-child {
  text-decoration: line-through;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.7);
}
.lineThrough {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  display: inline;
}
.priceContainer :nth-child(2) {
  color: #3792ec;
  font-weight: 700;
}

.checkBox {
  width: 40px;
  height: 18px;
  border-color: black;
  border-width: 2px;
  margin-left: 0px;
  position: unset;
}
.checkBox:focus {
  border-color: black;
}
.checkBox:checked {
  border-color: #0d6efd;
}
.voucherHeading {
  font-weight: bold;
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.voucherBtn {
  margin-left: 10px;
  color: #fff !important;
}

.counterContainer {
  text-align: left;
  margin-left: 35px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
  display: flex;
}

.counterContainer span {
  cursor: pointer;
}
.cursorNoDrop {
  cursor: no-drop !important;
}
.counterContainer span::selection {
  background: transparent;
}

.counterContainer .countNumberText {
  margin: 0 20px;
  width: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.btnsContainer {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  align-items: center;
}

.btnMainDiv {
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btnMainDiv span {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-left: 10px;
}
.cardBtn {
  background-color: #f0fafd;
  border: 1px solid #3792ec;
  color: #3792ec;
}
.cardBtn span {
  color: #3792ec;
}
.klarnaBtn {
  background-color: #ffb3c7;
  border: 1px solid #ffb3c7;
}
.AppleBtn {
  background-color: #000;
  border: 1px solid #000;
}
.AppleBtn span {
  color: #fff;
}
.applePayActiveBtn {
  background-color: rgb(132, 132, 132);
  border: 1px solid rgb(132, 132, 132);
}

.klarnaContainer {
  width: 100%;
  margin-top: 7%;
}
.klarnaContainer input {
  background-color: #fafafb;
  padding-left: 10px;
  border: 1px solid #f1f1f5;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.cardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  margin: 20px 0px;
}
.cardHeader > span {
  font-weight: bold;
  font-size: 18px;
}
.addCardBtn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.addCardBtn span {
  margin-left: 5px;
  font-weight: 600;
  font-size: 15px;
}

@media (max-width: 768px) {
  .addApplicationContainer img {
    display: none;
  }

  .addApplicationContainer > .priceContainer {
    margin-left: 40px;
  }
  .priceContainer p {
    margin-bottom: 5px;
  }
  .priceContainer {
    margin-bottom: 50px;
  }
  .checkBoxContainer {
    margin-bottom: unset !important;
  }

  .left > .priceContainer {
    margin-left: 50px;
  }
}

@media (min-width: 576px) {
  .paymentModalDialog {
    max-width: 1000px;
  }
}
@media (max-width: 440px) {
  .btnsContainer div {
    margin-bottom: 20px;
  }
  .btnsContainer {
    display: block;
  }
}
